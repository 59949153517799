/** @jsx jsx */
import {
  jsx,
  Grid,
  Flex,
  Box,
  Styled,
  Button,
  Container,
  Link as ThemeLink,
} from "theme-ui"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Pianominiatyyrejä lapsille" />
    <Grid
      sx={{
        gridTemplateColumns: [null, null, "1fr 1fr"],
        gridGap: [null, null, 4, 5],
        alignItems: "center",
        height: ["auto", null, "calc(100vh - 98px)"],
        maxHeight: ["auto", null, 900],
        mt: [60, 98],
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          order: [1, null, 0],
          zIndex: 1,
          maxWidth: ["auto", null, 760],
          px: [4, 5],
          mt: 3,
          mb: 5,
          ml: "auto",
        }}
      >
        <Styled.h1
          sx={{
            fontSize: ["10vw", 6, 7, 8],
          }}
        >
          Pianominiatyyrejä lapsille
        </Styled.h1>
        <Styled.p
          sx={{
            fontSize: [2, 3],
            textJustify: "auto",
          }}
        >
          Maiju Roineen pianokappaleet ovat löytäneet tiensä monen suomalaisen
          nuoren pianistin ja pianonsoiton opettajan sydämeen.{" "}
          <Styled.a as={Link} to="/kahdeksan-vuodenaikaa">
            Kahdeksan vuodenaikaa ja muita pianokappaleita
          </Styled.a>{" "}
          (2018) sekä{" "}
          <Styled.a as={Link} to="/fantasiakappaleita-lapsille">
            Fantasiakappaleita lapsille
          </Styled.a>{" "}
          (2019) sisältävät värikylläistä, koukuttavaa ja helposti lähestyttävää
          ohjelmistoa soitonopetukseen. Loppuvuodesta 2022 valmistuivat uudet
          julkaisut,{" "}
          <Styled.strong>
            Sateenkaaret – 15 pianokappaletta nuorille soittajille
          </Styled.strong>{" "}
          sekä pianonsoiton alkeisohjelmisto{" "}
          <Styled.strong>Minä soitan</Styled.strong>.
        </Styled.p>
      </Flex>
      <Img
        sizes={{
          ...data.lumottuRuusunen.childImageSharp.fluid,
        }}
        imgStyle={{ objectPosition: "bottom left" }}
        sx={{
          height: ["auto", "100%"],
          width: ["100%", "80%", "auto"],
          order: [0, null, 1],
          zIndex: 0,
          mx: ["auto", null, 0],
          mt: [4, 0],
        }}
      />
    </Grid>

    <Container>
      <Grid
        sx={{
          gridTemplateColumns: [null, null, "1fr 1fr"],
          gridGap: [0, null, 4, 5],
          alignItems: "center",
          justifyItems: "center",
          px: 4,
          mb: [6],
        }}
      >
        <Box sx={{ fontSize: [2, 3], order: 1, pr: [0, 0, 0, 5] }}>
          <Styled.h2>
            Sateenkaaret – 15 pianokappaletta nuorille soittajille
          </Styled.h2>
          <Styled.p>
            Sateenkaaret (2023) on viidentoista pianokappaleen ohjelmistovihko
            perustason 1-2 pianonsoittajille. Lotta Vuorman kuvittamassa
            vihkossa on nuorille pianisteille sopivia tunnelmallisia
            pedaalisävellyksiä sekä vauhdikkaita taiturikappaleita. Mukana on
            myös yksi nelikätinen kappale. Vihko sisältää myös alun perin Lakeus
            soi -pianotapahtumaan 2022 tilatun triptyykin, jonka osat ovat
            Yöllä, Elokuu ja Välkkyvät vedet.
          </Styled.p>
          <Button as="a" href="https://sulasol.fi/nuottikauppa/sateenkaaret/">
            Osta verkkokaupasta
          </Button>
        </Box>
        <Img
          sizes={{
            ...data.sateenkaaret.childImageSharp.fluid,
          }}
          sx={{
            width: "100%",
            maxWidth: 512,
            order: 0,
            mb: [4, null, 0],
            boxShadow:
              "0px 10px 15px -3px rgba(0,0,0,0.1),0px 20px 30px -3px rgba(0,0,0,0.05)",
          }}
        />
      </Grid>
      <Grid
        sx={{
          gridTemplateColumns: [null, null, "1fr 1fr"],
          gridGap: [0, null, 4, 5],
          alignItems: "center",
          justifyItems: "center",
          px: 4,
          mb: [5, 6],
        }}
      >
        <Box sx={{ fontSize: [2, 3], order: [1, 1, 0], pl: [0, 0, 0, 5] }}>
          <Styled.h2>
            <ThemeLink
              as={Link}
              variant="text"
              to="/fantasiakappaleita-lapsille"
            >
              Minä soitan
            </ThemeLink>
          </Styled.h2>
          <Styled.p>
            Minä soitan (2023) on alkeisohjelmistovihko pienille pianisteille.
            Tämä 35 kappaletta sisältävä ohjelmisto sopii hyvin aapisen
            läpikäyneelle piano-oppilaalle, ja siinä jatketaan nuotinluvun ja
            pianistisien taitojen harjoittamista ennen varsinaisen perustaso 1
            -ohjelmiston pariin siirtymistä. Vihkossa on isot nuotit, selkeä
            ulkoasu ja nuottitelineellä hyvin auki pysyvä kierresidonta.
            Ohjelmisto vaikeutuu pikkuhiljaa antaen soittavalle lapselle
            tilaisuuden kokea onnistumisen iloa taitojen karttuessa. Suurin osa
            sävellyksistä on uusia, mutta mukana on myös muutamia helppoja
            sovituksia tutuista suomalaisista kansanlauluista. Kauniit Lotta
            Vuorman kuvitukset tuovat silmälle iloa.
          </Styled.p>
          <Button as="a" href="https://sulasol.fi/nuottikauppa/mina-soitan/">
            Osta verkkokaupasta
          </Button>
        </Box>
        <Img
          sizes={{
            ...data.minasoitan.childImageSharp.fluid,
          }}
          sx={{
            width: "100%",
            maxWidth: 512,
            order: [0, 0, 1],
            mb: [4, null, 0],
            boxShadow:
              "0px 10px 15px -3px rgba(0,0,0,0.1),0px 20px 30px -3px rgba(0,0,0,0.05)",
          }}
        />
      </Grid>
      <Grid
        sx={{
          gridTemplateColumns: [null, null, "1fr 1fr"],
          gridGap: [0, null, 4, 5],
          alignItems: "center",
          justifyItems: "center",
          px: 4,
          mb: [6],
        }}
      >
        <Box sx={{ fontSize: [2, 3], order: 1, pr: [0, 0, 0, 5] }}>
          <Styled.h2>
            <ThemeLink as={Link} variant="text" to="/kahdeksan-vuodenaikaa">
              Kahdeksan vuodenaikaa ja muita pianokappaleita
            </ThemeLink>
          </Styled.h2>
          <Styled.p>
            Tiesitkö, että Lapissa on kahdeksan vuodenaikaa? Kahdeksan
            vuodenaikaa ja muita pianokappaleita -pianominiatyyrikokoelman
            teemana on pohjoinen luonto ja sen vaihtuvat värit.
          </Styled.p>
          <Button as={Link} to="/kahdeksan-vuodenaikaa" sx={{ mt: 3 }}>
            Lue lisää
          </Button>
        </Box>
        <Img
          sizes={{
            ...data.kahdeksanVuodenaikaaCover.childImageSharp.fluid,
          }}
          imgStyle={{
            objectFit: "contain",
          }}
          sx={{
            width: "100%",
            maxWidth: 512,
            boxShadow:
              "0px 10px 15px -3px rgba(0,0,0,0.1),0px 20px 30px -3px rgba(0,0,0,0.05)",
            order: 0,
            mb: [4, null, 0],
          }}
        />
      </Grid>
      <Grid
        sx={{
          gridTemplateColumns: [null, null, "1fr 1fr"],
          gridGap: [0, null, 4, 5],
          alignItems: "center",
          justifyItems: "center",
          px: 4,
          mb: [5, 6],
        }}
      >
        <Box sx={{ fontSize: [2, 3], order: [1, 1, 0], pl: [0, 0, 0, 5] }}>
          <Styled.h2>
            <ThemeLink
              as={Link}
              variant="text"
              to="/fantasiakappaleita-lapsille"
            >
              Fantasiakappaleita lapsille
            </ThemeLink>
          </Styled.h2>
          <Styled.p>
            Fantasiakappaleita lapsille sisältää tunnelmallisia perustasojen 1-2
            pianokappaleita. Vihkon alusta löytyvät helpoimmat kappaleet, ja
            loppupuolelta vaikeimmat. Samaa vihkoa voi siten käyttää
            soitto-opintojen eri vaiheissa.
          </Styled.p>
          <Button as={Link} to="/fantasiakappaleita-lapsille" sx={{ mt: 3 }}>
            Lue lisää
          </Button>
        </Box>
        <Img
          sizes={{
            ...data.fantasiakappaleitaCover.childImageSharp.fluid,
          }}
          imgStyle={{
            objectFit: "contain",
          }}
          sx={{
            width: "100%",
            maxWidth: 512,
            order: [0, 0, 1],
            mb: [4, null, 0],
            overflow: "visible",
            boxShadow:
              "0px 10px 15px -3px rgba(0,0,0,0.1),0px 20px 30px -3px rgba(0,0,0,0.05)",
          }}
        />
      </Grid>
    </Container>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    lumottuRuusunen: file(relativePath: { eq: "lumottu-ruusunen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    kahdeksanVuodenaikaa: file(
      relativePath: { eq: "kahdeksan-vuodenaikaa.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    kahdeksanVuodenaikaaCover: file(
      relativePath: { eq: "kahdeksan-vuodenaikaa-cover.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fantasiakappaleita: file(
      relativePath: { eq: "fantasiakappaleita-lapsille.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    fantasiakappaleitaCover: file(
      relativePath: { eq: "fantasiakappaleita-cover.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sateenkaaret: file(relativePath: { eq: "sateenkaaret-cover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    minasoitan: file(relativePath: { eq: "mina-soitan-cover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
